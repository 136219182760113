.user-container {
  background-color: #593d47;
  border: 10px solid #46333a;
  width: 320px;
  margin: 0 auto;
}
.user-body {
  position: relative;
  margin-top: 145px;
  display: block;
  padding-bottom: 40px;
}

.user-img-container {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  text-align: center;
  font-size: 21.6px;
  border: 10px solid #593d47;
  background: #593d47;
  position: absolute;
  top: -122px;
  left: 50%;
  transform: translateX(-50%);
  .user-img {
    width: 180px;
    height: 180px;
    background-size: cover;
    border-radius: 50%;
    background-repeat: no-repeat;
  }
}
.user-details {
  padding-top: 100px;
  padding-left: 15px;
  padding-right: 15px;
}

.btn-table-tip{
  background-color:#002556;
  color:#fff !important;
  &:hover {
    color: #FFF;
    background: #F7A901 none repeat scroll 0 0;
}
}