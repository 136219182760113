/*-------------------
	Banner Styles
----------------------*/

.single__account.tab-pane:not(.active) {
  display: none;
}

.acount__nav {
  margin-bottom: 60px;

  text-align: center;

  border-bottom: 2px solid #a3a3a3;
  .nav-item {
    width: 50%;
    a {
      font-size: 28px;
      line-height: 50px;

      position: relative;

      padding: 15px;

      transition: all 0.5s ease 0s;
      text-align: center;

      color: #a3a3a3;
      &:hover {
        color: #000;
      }
      &.active {
        color: #000 !important;
        background-color: transparent !important;
        &::before {
          position: absolute;
          bottom: -2px;
          left: 0;

          width: 100%;
          height: 2px;

          content: "";

          background: #000 none repeat scroll 0 0;
        }
      }
    }
  }
}

.dg__account {
  width: 570px;
  margin: 0 auto;
}

.single__account {
  .input__box {
    span {
      font-size: 18px;

      display: block;

      margin-bottom: 15px;

      text-transform: capitalize;

      color: #333;
    }
    input {
      width: 100%;
      height: 50px;
      margin-bottom: 21px;
      padding: 0 15px;

      border: 1px solid #bebebe;
      outline: none;
    }
  }
  .forget__pass {
    font-size: 18px;

    display: block;

    margin-bottom: 20px;

    text-align: right;

    color: #333;
  }
}

.account__btn {
  font-size: 19px;

  display: block;

  width: 100%;
  height: 45px;
  padding: 0 8px;

  transition: all 0.5s ease 0s;
  text-transform: capitalize;

  color: #fff;
  border: 0 none;
  border-radius: 30px;
  background: #002556 none repeat scroll 0 0;
  &:hover {
    color: #fff;
    background: #f7a901 none repeat scroll 0 0;
  }
}

/* Responsive Css*/

@media #{$lg-layout} {
}

@media #{$md-layout} {
}

@media #{$sm-layout} {
  .dg__account {
    width: 502px;
  }
}

@media #{$xs-layout} {
  .dg__account {
    width: 400px;
  }
}

@media #{$xxs-layout} {
  .dg__account {
    width: 300px;
  }
}
@media #{$xs-layout} {
  .search-container {
    .account__btn {
      width: 100% !important;
    }
  }
}
