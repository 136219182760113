/* external styles */
@import "~bootstrap/scss/bootstrap.scss";
@import "~animate.css/animate.min.css";
@import "~react-modal-video/scss/modal-video.scss";
@import "~swiper/css/swiper.css";
@import '../../../node_modules/react-toastify/dist/ReactToastify.css';
/* Needed Files*/
@import "variables";
@import "mixins";

/*******************
Basic Styles
********************/
@import "basic/reset";
@import "basic/typography";
@import "basic/helper-class";

/*******************
Elements Styles
********************/
@import "elements/button";
@import "elements/section-title";
@import "elements/spinner";
/*******************
Blocks Styles
********************/
@import "blocks/header";
@import "blocks/navigation";
@import "blocks/banner";
@import "blocks/slider";
@import "blocks/tickets";
@import "blocks/service";
@import "blocks/calculation";
@import "blocks/counterup";
@import "blocks/sucure";
@import "blocks/chart";
@import "blocks/software";
@import "blocks/blog";
@import "blocks/brand";
@import "blocks/newsletter";
@import "blocks/work";
@import "blocks/convert";
@import "blocks/breadcrumb";
@import "blocks/video";
@import "blocks/merchants";
@import "blocks/sidebar";
@import "blocks/blog-details";
@import "blocks/team";
@import "blocks/single-service";
@import "blocks/feature";
@import "blocks/currency-table";
@import "blocks/contact";
@import "blocks/bitcoin";
@import "blocks/login";
@import "blocks/footer";
@import "blocks/not-found";
@import "blocks/profile";
@import "blocks/user-wallet";
@import "blocks/tipped-user"
