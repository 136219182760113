.page-loader{
    position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.85);
z-index: 10;
}
.page-spinner {
    width: 5rem;
    height: 5rem;
    color: #29436e;
  }